.card-inner {
  @apply mx-auto grid-cols-3 gap-3 md:grid-cols-7;

  display: grid;
  max-height: 0px;
  visibility: hidden;
  pointer-events: none;

  &.active {
    max-height: unset;
    visibility: visible;
    pointer-events: auto;
  }
}

.wrapper {
  position: relative;

  & .text-offset {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-50%);
  }

  & .text {
    white-space: nowrap;
    pointer-events: none;
  }
}

.animate-before {
  transform: translateY(0px);
  opacity: 0;
  pointer-events: none;
}

.animate-after {
  transform: translateY(-20px);
  opacity: 0;
  pointer-events: none;
}

.card-container {
  @apply absolute left-1/2 bottom-0 flex w-[1700px] -translate-x-1/2 flex-col justify-start overflow-hidden;
  height: 70vh;
}

.card-column {
  @apply flex w-full flex-col items-center;
  transition: 1s;

  &:nth-child(1) {
    transition-delay: 0.6s;
  }
  &:nth-child(2) {
    transition-delay: 0.9s;
  }
  &:nth-child(3) {
    transition-delay: 1.3s;
  }
  &:nth-child(4) {
    transition-delay: 1.5s;
  }
  &:nth-child(5) {
    transition-delay: 1.8s;
  }
  &:nth-child(6) {
    transition-delay: 2.1s;
  }
  &:nth-child(7) {
    transition-delay: 2.3s;
  }
}

.card-item {
  @apply mb-3;
  width: 236px;
  height: 350px;
  border-radius: 12px;
}
.item:not(:first-child) {
  margin-top: 16px;
}

.hero-grid-module {
  @media (max-width: 576px) {
    .hero--grid--cover {
      @apply hidden;
    }
  }

  @media (min-width: 576px) {
    .hero--grid--cover {
      @apply absolute bottom-0;
      width: 100%;
      height: 133px;
      position: absolute;
      z-index: 1;

      background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgb(251 251 246) 98%);

      user-select: none;
      pointer-events: none;
    }
    &.text-position {
      &--center {
        h1 {
          @apply mx-auto text-center;
        }
        .block-content .list {
          @apply justify-center;
        }
      }
    }
  }
}

.hero-module {
  @media (max-width: 768px) {
    h1 {
      @apply mx-auto max-w-sm pt-1 pb-3 text-5xl font-normal;
    }

    .hero--bottom--gradient {
      @apply absolute bottom-0 z-[2] h-[50vh] w-full;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    }

    h4 {
      @apply text-xs font-medium uppercase text-[#F9D28B];
    }
    p {
      @apply mx-auto text-md font-normal first-of-type:max-w-sm;

      & > a {
        @apply mt-8 w-[70vw] bg-[#234E4C];
      }
    }

    h1,
    h4,
    p,
    a {
      @apply text-center;
    }
    .hero--cover {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;

      &--bg-gradient-white {
        background: linear-gradient(
          91deg,
          #fbfbf6 0.1%,
          rgba(255, 255, 255, 0.5) 49.57%,
          rgba(251, 251, 246, 0) 99.54%
        );
      }
    }
  }
}

.mobile--block--content {
  @apply w-full whitespace-pre-wrap px-4 pb-16 text-white;
}

@media screen and (max-height: 700px) {
  .mobile--block--content {
    @apply pb-28;
  }
}
