.block-content {
  ul {
    display: grid;
    width: 100%;
    z-index: 1;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    max-width: 750px;
    gap: 0;
    justify-content: center;
    margin: 0 auto;

    li {
      max-width: fit-content;
      justify-content: center;
    }
  }
}

.hidden-list-icons {
  svg {
    display: none;
  }
  .list {
    list-style-type: disc !important;
    padding-left: 20px;
    padding-bottom: 0;
    p {
      margin: 2.5px 0;
    }
    p::before {
      content: "•";
      color: #000;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }
}